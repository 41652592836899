<template>
  <div style="height: calc(100vh - 120px); width: 100%" class="ma-2">
    <l-map
      v-if="showMap"
      :zoom="zoom"
      :center="center"
      :options="mapOptions"
      style="height: 100%;z-index:0"
      @update:center="centerUpdate"
      @update:zoom="zoomUpdate"
    >
      <l-tile-layer :url="url" :attribution="attribution" />
      <div v-for="(i, index) in listAddress" :key="index">
        <l-marker :lat-lng="setFormat(i.lat, i.lng)" v-if="i.lat !== null">
          <l-icon
            :icon-url="setIcon(i.สถานะ)"
            :icon-size="dynamicSize"
            :icon-anchor="dynamicAnchor"
          />
          <l-popup>
            <span class="font-weight-bold fs-18">{{ i.ชื่อหน่วยงาน }}</span>
            <br />
            <span class="fs-16">
              <strong>ที่ตั้ง: </strong>{{ i.ที่ตั้ง }}
            </span>
            <br />
            <span class="fs-16">
              <strong>โทรศัพท์: </strong> {{ i.เบอร์โทรศัพท์ติดต่อ }}
            </span>
            <br />
            <span class="fs-16">
              <strong>หัวหน้าหน่วยงาน: </strong>
              {{ i.หัวหน้าหน่วยงาน }}
            </span>
            <br />
            <span class="fs-16">
              <strong>ตำแหน่ง: </strong> {{ i.ตำแหน่ง }} </span
            ><br />
            <a
              :href="
                'https://www.google.com/maps/search/?api=1&query=' +
                  i.lat +
                  ',' +
                  i.lng
              "
              target="_blank"
              ><span class="fs-14">เปิดด้วย Google Map</span></a
            >
            <div @click="innerClick"></div>
          </l-popup>
        </l-marker>
      </div>
    </l-map>
  </div>
</template>

<script>
import { LMap, LTileLayer, LMarker, LPopup, LIcon } from "vue2-leaflet";
// import { icon } from "leaflet";
import address from "../assets/json/address.json";
export default {
  name: "Example",
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LPopup,
    LIcon
  },
  data() {
    return {
      zoom: 6,
      center: [13.736717, 100.523186],
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      attribution:
        '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      withPopup: [13.736717, 100.523186],
      showParagraph: false,
      mapOptions: {
        zoomSnap: 0.5
      },
      showMap: true,
      listAddress: [],
      iconSize: 35
    };
  },
  computed: {
    dynamicSize() {
      return [this.iconSize, this.iconSize * 1.15];
    },
    dynamicAnchor() {
      return [this.iconSize / 2, this.iconSize * 1.15];
    }
  },
  created() {
    this.queryAddress();
  },
  methods: {
    zoomUpdate(zoom) {
      this.currentZoom = zoom;
    },
    centerUpdate(center) {
      this.currentCenter = center;
    },
    showLongText() {
      this.showParagraph = !this.showParagraph;
    },
    innerClick() {
      alert("Click!");
    },
    queryAddress() {
      // console.log(address);
      this.listAddress = address;
    },
    setFormat(lat, lng) {
      if (lat !== null && lng !== null) {
        return [lat, lng];
      }
    },
    setIcon(status) {
      if (status === "สจป") {
        return require("../assets/pin2.svg");
      } else {
        return require("../assets/pin.svg");
      }
    }
  }
};
</script>
